import { useContext, useEffect, useState } from "react";
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import Scrollbar from "../../components/scrollbar";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
import { searchSlip } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";

import '../../styles/filter.scss'

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "id", label: "Game", alignRight: false },
  { id: "gameNumber", label: "Game number", alignRight: false },
  { id: "stake", label: "Stake", alignRight: false },
  { id: "num", label: "Picked numbers", alignRight: false },
  { id: "win", label: "Net win", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
];

export default function SlipDetail(params) {
  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState(null);
  const [ticketNumber, setTicketNumber] = useState("");

  const handleFetchData = () => {
    searchSlip(owner, setOwner, dispatch, setLoader, ticketNumber);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    (page > 0 && owner) ? Math.max(0, (1 + page) * rowsPerPage - owner?.length) : 0;

  return (
    <>
      <Helmet>
        <title> Dashboard | Minimal UI </title>
      </Helmet>
      <Container maxWidth="xl">

        <Card sx={{ margin: '1rem 0' }}>
          <Grid container spacing={3} className="fillterGridLeft">
            <Grid item xs={9} sm={9} xl={6}>
              <TextField type="number" placeholder="Slip number" value={ticketNumber} onChange={(e) => setTicketNumber(e.target.value)} fullWidth />
            </Grid>
            <Grid item xs={3} sm={3} xl={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              {
                loader ? (
                  <Button variant="contained" fullWidth style={{ height: '48px', padding: '10px', background: '#7FD858' }}><CircularProgress sx={{ color: 'white' }} /></Button>
                ) : (
                  <Button variant="contained" fullWidth onClick={handleFetchData} style={{ height: '48px', background: '#7FD858' }}>Search Slip</Button>
                )
              }
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ margin: '1rem 0' }}>
          <Scrollbar >
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  // rowCount={1}
                  // numSelected={1}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    owner && (
                      <TableRow
                        hover
                      // key={owner.id}
                      // tabIndex={-1}
                      // role="checkbox"
                      // selected={selectedUser}
                      >
                        <TableCell>
                          {owner.id}
                        </TableCell>

                        <TableCell align="left">{owner?.gameType}</TableCell>
                        <TableCell align="left">{owner.game?.gameNumber}</TableCell>
                        <TableCell align="left">{owner.totalStake}</TableCell>
                        <TableCell align="left">
                          {(owner?.numberPick) && JSON.parse(owner?.numberPick).map((pick, index) => (
                            <span key={index}>
                              {pick.selection && Array.isArray(pick.selection)
                                ? pick.selection.join(', ')
                                : pick.selection}
                              {pick.val && Array.isArray(pick.val)
                                ? pick.val.join(', ')
                                : pick.val}
                              {index < JSON.parse(owner?.numberPick).length - 1 && ' : '}
                            </span>
                          ))}</TableCell>
                        <TableCell align="left">{owner.netWinning}</TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {owner.status}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    )

                  }
                  {(false && !loader) && (
                    <TableRow
                      style={{
                        height: '40px',
                      }}
                    >
                      <TableCell
                        colSpan={8}
                        align="center"
                        style={{
                          height: "paddingHeight",
                          padding: "40px",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        No Shops Found
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {/* {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Scrollbar>

        </Card>
      </Container>
      {/* <AddShop open={open} setOpen={setOpen} handleFetchData={handleFetchData} /> */}
    </>
  );
};
