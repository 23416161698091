import { useContext, useEffect, useState } from "react";
import { Container, useTheme, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { getShop, getShopReport } from "../../data/fetchShopOwner";
import RetailTable from "./RetailTable";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import '../../styles/filter.scss'
import { ReportFilter } from "./ReportFilter";


export default function RetailReport(params) {
  const theme = useTheme();

  const { data, dispatch } = useContext(CampaignContext);
  const [owner, setOwner] = useState([]);
  const [shop, setShop] = useState([]);
  const [loader, setLoader] = useState(false);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set time to start of the day
  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999); // Set time to end of the day

  const [filterData, setFilterData] = useState({
    from: today,
    to: endOfDay,
    shop: [],
    shopOwner: []
  })

  const handleFetchShop = () => {
    getShop(shop, setShop, dispatch, setLoader);
  }

  const handleFetchData = () => {
    getShopReport(owner, setOwner, dispatch, setLoader, filterData);
  }
  useEffect(() => {
    handleFetchShop();
    handleFetchData();
  }, []);


  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Revenue Report
          </Typography>
        </Stack>

        <ReportFilter filterData={filterData} setFilterData={setFilterData} handleFetchData={handleFetchData} loader={loader} shop={shop} />

        <RetailTable owner={owner} loader={loader} cash={false} filterData={filterData} handleFetchData={handleFetchData} />

      </Container>
    </>
  );
};
