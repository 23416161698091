import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Button, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
// component
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  padding: theme.spacing(0, 1, 0, 3),

  [theme.breakpoints.down('sm')]: {
    // Styles for screens smaller than or equal to small (sm) size
    flexDirection: 'column-reverse', // For example, switch to a column layout on small screens
    justifyContent: 'center',
    alignItems: 'left',
    gap: 15,
    margin: '10px 0',
    height: 'auto', // Adjust height as needed for mobile layout
  },
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

ShopOwnerListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  openDialog: PropTypes.func,
  buttonName: PropTypes.string,
};

export default function ShopOwnerListToolbar({ numSelected, filterName, onFilterName, openDialog, buttonName }) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {/* <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '2rem' }}>
        <Button variant="contained" style={{ background: '#7FD858' }} startIcon={<Iconify icon="eva:plus-fill"
        />} onClick={openDialog}>
          {buttonName}
        </Button>
      </div> */}
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search ..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>
      )} */}
    </StyledRoot>
  );
}
