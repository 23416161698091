// styles.js

import { styled } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

export const CustomDialog = styled(Dialog)({
  // Your custom styles for the entire dialog container
});

export const CustomDialogTitle = styled(DialogTitle)({
  // Your custom styles for the dialog title
  background: "#5db438", color: "white", marginBottom: "10px"
});

export const CustomDialogActions = styled(DialogActions)({
  // Your custom styles for the dialog actions


  '& button': {
    // Your custom styles for the buttons
    // Example styles:
    background: "#5db438",
    color: "white",
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
});
