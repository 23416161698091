import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/styles";
import {
  Button, Container, Grid, Typography, useTheme, Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { Edit } from "@mui/icons-material";
import axios from "axios";
// components
import Label from "../../components/label";
import Scrollbar from "../../components/scrollbar";
import { UserListHead, UserListToolbar } from "../../sections/@dashboard/user";
// mock
import { getShop, handleChangeShopStatus } from "../../data/fetchShopOwner";
import { CampaignContext } from "../../layouts/dashboard/DashboardLayout";
import ShopOwnerListToolbar from "./ShopOwnerListToolbar";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, selectedowner) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1 || _user?.location?.toLowerCase().indexOf(query?.toLowerCase()) !== -1 || _user?.owner?.name?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(0,0,0, 0.08)',
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TABLE_HEAD = [
  // { id: "id", label: "Id", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "owner", label: "Owner", alignRight: false },
  { id: "location", label: "Location", alignRight: false },
  { id: "created_at", label: "Created", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  // { id: "isVerified", label: "Action", alignRight: false },
  // { id: "isVerifieffd", label: "Verified", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
  { id: "", label: "Action", align: true },
];

export default function ManageShop(params) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { data, dispatch } = useContext(CampaignContext);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updated_at");
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loader, setLoader] = useState(false);
  const [owner, setOwner] = useState([]);

  const handleOpen = () => {
    console.log('handle');
    setOpen(true);
  };

  const handleFetchData = () => {
    getShop(owner, setOwner, dispatch, setLoader);
  }
  useEffect(() => {
    handleFetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = owner.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - owner.length) : 0;

  const filteredUsers = applySortFilter(
    owner,
    getComparator(order, orderBy),
    filterName
  );
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Manage Shop</title>
      </Helmet>
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Manage Shop
          </Typography>
        </Stack>

        <Card>
          <ShopOwnerListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            openDialog={handleOpen}
            buttonName="Create Shop"
          />

          <Scrollbar>
            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={owner.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {
                    loader ? (<div style={{ height: '50px' }}><CircularProgress /></div>) :
                      filteredUsers && filteredUsers
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const {
                            id,
                            name,
                            location,
                            status,
                            owner,
                            // status,
                          } = row;

                          return (
                            <StyledTableRow
                              hover
                              key={id}
                            // tabIndex={-1}
                            // role="checkbox"
                            // selected={selectedUser}
                            >
                              <TableCell>
                                {((page * 10) + index + 1)}
                              </TableCell>

                              <TableCell component="th" scope="row" padding="none">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">{owner?.name}</TableCell>
                              <TableCell align="left">{location}</TableCell>
                              <TableCell align="left">{row.created_at.substring(0, 10)}</TableCell>

                              <TableCell align="left">
                                <Label
                                  color={
                                    (status === "inactive" && "error") || "success"
                                  }
                                >
                                  {sentenceCase(status)}
                                </Label>
                              </TableCell>

                              <TableCell align="left">
                                <Button variant="contained" style={{ background: "#7FD858", color: 'white' }} fullWidth startIcon={<Edit />} onClick={() => handleChangeShopStatus(owner, setOwner, dispatch, setLoader, row)}>change status</Button>
                              </TableCell>
                            </StyledTableRow>
                          );
                        })
                  }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
};
